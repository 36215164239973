export default [
  {
    bpm: 126,
    chroma_stft: 0.54,
    neighbour_1: 20200914012761,
    neighbour_2: 20200919012766,
    rmse: 0.03,
    spectral_bandwidth: 4141,
    spectral_centroid: 5690,
    spectral_rolloff: 9586,
    uid: 20200913012760,
    zero_crossing_rate: 0.18,
  },
  {
    bpm: 123,
    chroma_stft: 0.56,
    neighbour_1: 20200913012760,
    neighbour_2: 20200915012762,
    rmse: 0.03,
    spectral_bandwidth: 4088,
    spectral_centroid: 5570,
    spectral_rolloff: 9381,
    uid: 20200914012761,
    zero_crossing_rate: 0.18,
  },
  {
    bpm: 123,
    chroma_stft: 0.41,
    neighbour_1: 20200919012766,
    neighbour_2: 20200920012767,
    rmse: 0.04,
    spectral_bandwidth: 3843,
    spectral_centroid: 5836,
    spectral_rolloff: 9241,
    uid: 20200915012762,
    zero_crossing_rate: 0.22,
  },
  {
    bpm: 126,
    chroma_stft: 0.43,
    neighbour_1: 20200917012764,
    neighbour_2: 20200915012762,
    rmse: 0.04,
    spectral_bandwidth: 3705,
    spectral_centroid: 5702,
    spectral_rolloff: 8888,
    uid: 20200916012763,
    zero_crossing_rate: 0.23,
  },
  {
    bpm: 123,
    chroma_stft: 0.44,
    neighbour_1: 20200915012762,
    neighbour_2: 20200919012766,
    rmse: 0.04,
    spectral_bandwidth: 3708,
    spectral_centroid: 5720,
    spectral_rolloff: 8998,
    uid: 20200917012764,
    zero_crossing_rate: 0.22,
  },
  {
    bpm: 120,
    chroma_stft: 0.42,
    neighbour_1: 20200921012768,
    neighbour_2: 20200915012762,
    rmse: 0.03,
    spectral_bandwidth: 3914,
    spectral_centroid: 6195,
    spectral_rolloff: 9704,
    uid: 20200918012765,
    zero_crossing_rate: 0.24,
  },
  {
    bpm: 123,
    chroma_stft: 0.4,
    neighbour_1: 20200915012762,
    neighbour_2: 20200920012767,
    rmse: 0.03,
    spectral_bandwidth: 3846,
    spectral_centroid: 5835,
    spectral_rolloff: 9216,
    uid: 20200919012766,
    zero_crossing_rate: 0.22,
  },
  {
    bpm: 123,
    chroma_stft: 0.36,
    neighbour_1: 20200919012766,
    neighbour_2: 20200921012768,
    rmse: 0.03,
    spectral_bandwidth: 3839,
    spectral_centroid: 5823,
    spectral_rolloff: 9196,
    uid: 20200920012767,
    zero_crossing_rate: 0.22,
  },
  {
    bpm: 123,
    chroma_stft: 0.35,
    neighbour_1: 20200920012767,
    neighbour_2: 20200919012766,
    rmse: 0.03,
    spectral_bandwidth: 3844,
    spectral_centroid: 5874,
    spectral_rolloff: 9244,
    uid: 20200921012768,
    zero_crossing_rate: 0.22,
  },
  {
    bpm: 123,
    chroma_stft: 0.33,
    neighbour_1: 20200920012767,
    neighbour_2: 20200919012766,
    rmse: 0.04,
    spectral_bandwidth: 3686,
    spectral_centroid: 5929,
    spectral_rolloff: 9069,
    uid: 20200922012769,
    zero_crossing_rate: 0.23,
  },
];
